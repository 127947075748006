.CopyLinkBtn {
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
  top: 6px;
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  &:active {
    opacity: 0.5;
  }
}
