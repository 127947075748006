.SortSelector {
  &__label {
    display: flex;
    font-size: 14px;
    color: #495360;
  }

  &__btn {
    outline: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    color: #0071c9;
    cursor: pointer;
  }

  .Menu {
    z-index: 3;
    width: 156px;
    position: absolute;
    padding: 5px 0;
    background: #fff;
    box-shadow: 0 6px 9px 0 rgba(87, 110, 130, 0.37);

    .menu-item {
      cursor: pointer;
      padding: 10px 12px;
      font-family: Verdana;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: -0.41px;
      color: #000000;

      &:hover {
        background: #f7f8f8;
      }

      &:active {
        opacity: 0.7;
      }
    }
  }
}
