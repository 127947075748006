@import 'common';

.Guide {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  position: relative;

  @include media-desktop(){
    .screen-head {
      margin-bottom: rem(55);
    }
  }
}
