@import 'common';

.Topics {
  @extend %Screen;

  @include media-desktop() {
    .screen-container {
      display: flex;
      flex-direction: row;
      //warning! check to use only flex-basis: auto not flex-basis: 0 for IE11 support
      flex: 1 1 auto;
      padding: 0 rem(50) 0 rem(115);
      position: relative;

      .TopicsList {
        flex-grow: 1;
        flex-shrink: 0;
      }

      .TopicsList.TopPicksList {
        padding: 0;
      }
    }

    &.one .screen-container {
      flex-direction: column;
    }
  }

  @include media-mobile("landscape") {
    &.one.video {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 4;

      & .screen-head {
        display: none;
      }

      & .video-player-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  @include media-desktop {
    .Topics__content-center {
      // @extend .screen-text ;

      width: rem(840);
      align-self: center;
      margin-left: rem(-115); //negate screen-container padding so topic looks centered
    }

    &.one.video .Topics__content-center {
      width: rem(840);
    }
  }

  .topics {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;

    &-subtitle {
      font-size: rem(18);
      line-height: rem(28);

      color: #000;
      font-weight: bold;
      position: relative;
      padding: rem(17) rem(40) rem(17) rem(20);

      flex-shrink: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: left;

      box-shadow: inset 0 -1px #E2E2E2;
      background-color: #f7f7f7;

      @include media-desktop {
        font-size: rem(14);
        line-height: rem(34);
        margin: 0 0 rem(20);
        padding: rem(15) rem(40) rem(15) rem(0);
        background: none;
        text-transform: uppercase;
        color: #000;
      }
    }

    &-breadcrumb {
      font-size: rem(14);

      line-height: rem(34);
      margin: 0 0 rem(20);
      padding: rem(15) rem(15) rem(15) rem(0);

      font-weight: 500;
      position: relative;

      flex-shrink: 0;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      text-transform: uppercase;
      box-shadow: inset 0 -1px #E2E2E2;
      color: #b2b2b2;

      li {
        display: flex;
        align-items: center;

        a {
          text-decoration: none;
          color: #5E5757;

        }

        .icon {
          display: block;
          margin: 0 rem(22);

          width: rem(4);
          height: rem(7);
          transform: rotate(180deg);

          .stroke {
            stroke: #b2b2b2;
          }
        }

        &:last-child {
          .icon {
            display: none;
          }

          color: #5E5757;

        }
      }

      &.inactive li:last-child {
        color: inherit;

      }
    }
  }

  .video-container {

    @include media-desktop {
      display: flex;
      flex-flow: row nowrap;
      flex: 1 0 rem(600);

      .video-player-container {
        order: 1;
      }
    }

    @include media-mobile {
      padding: rem(20);
    }

    .video-player-container,
    .video-player-native {
      padding-bottom: rem(20);

      .vjs-poster {
        background-repeat: repeat;
      }

      @include media-desktop {
        max-width: rem(980);
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
      }
    }

    .video-markers-container {

      @include media-desktop {
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: rem(40);
        max-width: rem(320);
      }

      h1 {
        color: black;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.7rem;
        line-height: 3rem;
        border-top: 2px solid #f7f7f7;

        @include media-mobile {
          display: none; // requirement from UX team to hide the header on mobile devices to allow more space on screen
        }
      }

      .video-markers {
        background-color: #f7f7f7;
        margin: 0 0 rem(20) 0;
        padding: rem(10) rem(30);

        button {
          color: #495360;

          font-weight: 300;
          font-size: 0.7rem;
          line-height: 1rem;

          margin: rem(20) 0;
          display: block;
          cursor: pointer;
          text-align: left;

          @include media-mobile {
            font-size: 0.8rem;
            line-height: 1.1rem;
            margin: rem(25) 0;
          }

          @include media-desktop {
            &:hover {
              transform: scale(1.02);
            }
          }

          &:active {
            transform: scale(1);
          }
        }
      }
    }

    .youtubeContainer {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      overflow: hidden;
      margin-bottom: 50px;

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .video-player-native {
    position: relative;
    text-align: center;
    display: block;
    flex-shrink: 0;

    img {
      width: 100%;
    }

    div.play {
      position: absolute;
      transform: translate(0, 0);
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      div {

        font-size: 2em;
        line-height: 1.4em;
        height: 1.5em;

        width: 3em;
        display: block;
        position: absolute;
        cursor: pointer;
        opacity: 1;
        color: white;
        border: .06666em solid #fff;
        font-weight: 400;
        background-color: rgba(43, 51, 63, .7);
        border-radius: .3em;
        transition: all .4s;

        font-family: VideoJS;

        &:before {
          content: "\F101";
        }
      }
    }
  }

  .topic-back {
    position: relative;
    font-size: rem(14);
    color: #000;
    text-transform: uppercase;
    line-height: rem(15);
    font-weight: normal;
    flex-shrink: 0;

    a {
      text-decoration: none;
      color: #000;
    }

    .icon {
      position: absolute;
      width: rem(5);
      height: rem(8);
      left: rem(-22);
      top: rem(3);
      //transform: rotate(180deg);

      .stroke {
        stroke: #000;
        stroke-width: 2;
      }
    }
  }

  .topic-title {
    font-size: rem(28);
    color: #000;
    margin: 0 rem(20) rem(20) 0;
    flex-shrink: 0;
    text-transform: uppercase;
    font-weight: bold;

    @include media-mobile() {
      display: none;
    }
  }

  .topic-content {
    @extend .screen-text;

    @include media-desktop() {
      width: rem(840);
      align-self: center;
    }

    @include media-mobile() {
      margin: rem(20) rem(20) 0;
    }

    main {
      padding-bottom: rem(20);
    }

    overflow-wrap: break-word;
    
    .p,
    .title,
    p,
    li,
    strong {
      color: #495360;
      font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      font-size: rem(16);
      line-height: rem(24);
      font-weight: 400;
    }

    .title {
      font-weight: 700;
    }

    .note {
      &>* {
        padding: rem(24);
        color: #495360;
      }

      ul,
      ol {
        padding-left: rem(40);
      }

      &_note>* {
        padding-left: 0;
      }

      &::before {
        padding: rem(8) 0;
        color: #000;
        font-weight: 700;
      }

      li {
        margin: 0 !important;
      }

      &.tip,
      &.danger,
      &.caution,
      &.notice {
        .note__title {
          display: none;
        }
      }

      &.tip:before {
        content: 'INFO:';
        background: url("../svg/note-info.svg") no-repeat center left;
        padding-left: 30px;
      }

      &.danger {
        background-color: rgba(255, 120, 0, 0.2);
      }

      &.danger:before {
        content: 'WARNING';
        background: url("../svg/note-warning.svg") no-repeat center left 31%;
        background-color: #FF7800;
      }

      &.caution {
        background-color: rgba(238, 210, 2, 0.2);
      }

      &.caution:before {
        content: 'CAUTION';
        background: url("../svg/note-warning.svg") no-repeat center left 31%;
        background-color: #EED202;
      }

      &.notice {
        background-color: rgba(47, 110, 199, 0.2);
      }

      &.notice:before {
        content: 'NOTICE';
        background-color: #2F6EC7;
        color: #fff;
      }

      &.danger,
      &.caution,
      &.notice {
        margin-bottom: rem(24);
        &:before {
          display: flex;
          justify-content: center;

          @include media-desktop() {
            background-position: 43%;
          }
        }
        .p {
          font-weight: bold;
        }
      }
    }

    strong {
      font-weight: 600;
    }

    .topic-title,
    .title:not(.sectiontitle) {
      display: none;
    }

    .image {
      vertical-align: middle;
    }

    .expandable-img-wrapper {
      position: relative;
      display: inline-block;
      cursor: pointer;
      margin-bottom: 4px;
    }

    .fig {
      position: relative;
      margin: rem(20) auto rem(25);
      width: fit-content;

      &.with-expandable-img {
        text-align: center;
      }

      .image {
        max-height: none;
        max-width: 100%;
      }

      &.with-expandable-img .expandable-img-wrapper:after {
        content: '';
        display: none;
        width: rem(36);
        height: rem(36);
        position: absolute;
        top: rem(10);
        right: rem(10);
        border: none;
        box-shadow: none;
        opacity: 0.85;
        background: rgba(0, 0, 0, .45) url('../svg/expand.svg') 50% 50% no-repeat;
        background-size: 70%;

        @include media-mobile() {
          width: rem(24);
          height: rem(24);
          display: block;
        }
      }

      @include media-desktop() {
        &.with-expandable-img .expandable-img-wrapper:hover:after {
          display: block;
        }
      }
    }

    .ul,
    .ol {
      padding-left: rem(20);

      .li {
        margin: rem(5) 0;
      }
    }

    .parentlink {
      display: none;
    }

    img.icon {
      border: 1px solid #e6e6e6;
      padding: rem(15);
      margin: rem(10) auto;
      display: block;
    }

    p.grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0;

      a {
        border: 1px solid #e6e6e6;
        background-color: #fff;
        display: flex;
        min-width: 27%;
        margin: 6% 3% 0 3%;
        padding: rem(15);

        @include media-desktop() {
          min-width: auto;
          width: rem(165);
          height: rem(140);
          margin: rem(10);
          padding: rem(30);
        }

        img {
          min-width: 100%;
          min-height: 100%;

          // Safari only fix for the icons scaling, see details: https://tweddle.atlassian.net/browse/OI-2043
          @supports (-webkit-backdrop-filter: blur(1px)) {
            min-width: auto;
            min-height: auto;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .sup {
      vertical-align: super;
      font-size: smaller;
    }
  }
}

.note__title ,.note_note p {
  font-weight: bold !important;
}
