@import "../utils";

// Nissan brand theme
$nissan-primary-color: #0073BF;
$nissan-secondary-color: #fff;
$nissan-screenhead-background: #F4F5F7;
$nissan-body-color: #eeefef;
$nissan-regular-color: #7c8d98;
$nissan-contrast-color: #000;
$nissan-background-color: #f2f2f2;
$nissan-logo: '../../images/nissan_logo.svg';
$nissan-logo-promo: '../../images/nissan_logo_promo.png';

/* BRAND FONTS */

@font-face {
  font-family: 'nissan_brand';
  src: url('../fonts/nissanbrand-bold-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/nissanbrand-bold-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/nissanbrand-bold-webfont.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/nissanbrand-bold-webfont.woff') format('woff'), // Modern Browsers
    url('../fonts/nissanbrand-bold-webfont.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/nissanbrand-bold-webfont.svg#nissan_brandbold') format('svg'); // Legacy iOS
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'nissan_brand';
  src: url('../fonts/nissanbrand-bold-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/nissanbrand-bold-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/nissanbrand-bold-webfont.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/nissanbrand-bold-webfont.woff') format('woff'), // Modern Browsers
    url('../fonts/nissanbrand-bold-webfont.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/nissanbrand-bold-webfont.svg#nissan_brandbold') format('svg'); // Legacy iOS
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'nissan_brand';
  src: url('../fonts/nissanbrand-italic-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/nissanbrand-italic-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/nissanbrand-italic-webfont.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/nissanbrand-italic-webfont.woff') format('woff'), // Modern Browsers
    url('../fonts/nissanbrand-italic-webfont.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/nissanbrand-italic-webfont.svg#nissan_branditalic') format('svg'); // Legacy iOS
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'nissan_brand';
  src: url('../fonts/nissanbrand-light-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/nissanbrand-light-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/nissanbrand-light-webfont.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/nissanbrand-light-webfont.woff') format('woff'), // Modern Browsers
    url('../fonts/nissanbrand-light-webfont.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/nissanbrand-light-webfont.svg#nissan_brandlight') format('svg'); // Legacy iOS
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'nissan_brand';
  src: url('../fonts/nissanbrand-regular-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/nissanbrand-regular-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/nissanbrand-regular-webfont.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/nissanbrand-regular-webfont.woff') format('woff'), // Modern Browsers
    url('../fonts/nissanbrand-regular-webfont.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/nissanbrand-regular-webfont.svg#nissan_brandregular') format('svg'); // Legacy iOS
  font-weight: normal;
  font-style: normal;
}

// =========================
// Nissan specific styling
// =========================

.expand-image-modal.Nissan {
  font-family: 'nissan_brand', sans-serif;
}

div.nissan {

  & *,
  .Topics .topic-content .note * {
    font-family: 'nissan_brand', sans-serif;
  }

  .topic-content {

    p,
    .p,
    li,
    strong {
      font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    }

  }

  // Logo
  .logo {
    background-image: url($nissan-logo-promo);
  }

  .screen-logo,
  .terms-logo {
    background: none;

    &:after {
      content: "";
      display: block;
      background-image: url($nissan-logo);
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      z-index: 1;
    }
  }

  .screen-container {
    @include media-mobile() {
      background: $nissan-screenhead-background;
    }
  }

  .screen-logo {
    width: rem(75);

    &:after {
      width: rem(76);
      height: rem(76);
      bottom: rem(4);
    }
  }

  .terms-logo {
    width: rem(56);
    flex: 0 0 rem(56);

    &:after {
      top: rem(12);
      width: rem(56);
      height: rem(56);
    }
  }

  @include media-desktop {
    .terms-logo {
      width: rem(76);
      flex: 0 0 rem(76);
      align-self: flex-start;

      &:after {
        width: rem(76);
        height: rem(94);
        top: rem(15);
      }
    }
  }

  // Splash screen colors
  .Vehicles,
  .BrandsSelectorScreen,
  .Promo,
  .Guide {
    background: $nissan-body-color;
    color: $nissan-secondary-color;

    .hint {
      color: #E57593;
    }

    .screen-title {
      color: $nissan-contrast-color;
    }

    .screen-overlay {
      background: $nissan-primary-color;
      color: $nissan-secondary-color;

      .PrimaryButton {
        color: $nissan-contrast-color;
        background: $nissan-secondary-color;
      }

      .icon .fill {
        fill: rgba($nissan-secondary-color, .5);
      }
    }

    @include media-mobile() {
      .screen-head {
        box-shadow: none;
      }

      .screen-title,
      .screen-back>* {
        color: $nissan-contrast-color;

        .icon {
          margin-right: rem(20);
        }
      }

      .screen-back .icon .stroke {
        stroke: $nissan-contrast-color;
      }

      .screen-topmenu {
        .topmenu-trigger .icon .fill {
          fill: $nissan-contrast-color;
        }

        .topmenu-inner {
          box-shadow: 0 rem(10) rem(15) rem(-5) rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  // Regular screen colors
  .Guide {
    color: $nissan-regular-color;
    background: $nissan-secondary-color;
  }

  .Guide,
  .Vehicles {
    .screen-head {
      @include media-mobile() {
        background: $nissan-body-color;
        border-bottom: 3px solid $nissan-primary-color;
        box-shadow: none;

        .screen-title {
          color: $nissan-contrast-color;
        }
      }
    }
  }

  //loader styling
  .Loader {
    background-color: $nissan-primary-color;

    .loader-default {
      &>div {
        background-color: $nissan-secondary-color;
      }
    }

    .loader-ios {
      @keyframes spinner-ios-nissan {
        0% {
          background-color: $nissan-secondary-color;
        }

        100% {
          background-color: transparent;
        }
      }

      @keyframes spinner-ios-nissan-inverse {
        0% {
          background-color: $nissan-primary-color;
        }

        100% {
          background-color: transparent;
        }
      }

      &>div {
        animation-name: spinner-ios-nissan;
      }
    }

    .loader-android {
      circle {
        stroke: $nissan-secondary-color;
      }
    }

    &.inverse {
      background-color: $nissan-secondary-color;
      color: $nissan-regular-color;

      @include media-mobile() {
        background-color: transparent;
      }

      .loader-default>div {
        background-color: $nissan-primary-color;
      }

      .loader-ios>div {
        animation-name: spinner-ios-nissan-inverse;
      }

      .loader-android {
        circle {
          stroke: $nissan-primary-color;
        }
      }
    }
  }

  .NavBar {
    @include media-mobile() {
      background: #f9f9f9;

      .nav-link {
        color: $nissan-regular-color;

        .icon {
          .active {
            display: none;
          }

          .fill {
            fill: $nissan-regular-color;
          }

          .stroke {
            stroke: $nissan-regular-color;
          }
        }

        &.active {
          color: $nissan-primary-color;

          .icon .active {
            display: inherit;
          }

          .icon .inactive {
            display: none;
          }

          .icon .fill {
            fill: $nissan-primary-color;
          }

          .icon .stroke {
            stroke: $nissan-primary-color;
          }
        }
      }
    }

    @include media-desktop() {
      background: $nissan-secondary-color;
      border-top: 1px solid #DCDCDE;
      border-bottom: 1px solid #DCDCDE;

      .nav-link {
        color: #000000;
        font-weight: 300;


        font-size: rem(18);
        text-transform: none;
        opacity: 1;

        &.active {
          color: $nissan-primary-color;
          border-bottom: 2px solid $nissan-primary-color;
        }
      }
    }
  }

  .VehicleList {
    background: $nissan-secondary-color;

    h2 {
      color: $nissan-contrast-color;
    }

    .model {
      color: $nissan-regular-color;

      .name {
        color: $nissan-contrast-color;
      }
    }
  }

  .VehicleYearSelector {
    @include media-desktop() {
      border-top: 1px solid #DCDCDE;
      border-bottom: 1px solid #DCDCDE;
      background-color: $nissan-secondary-color;

      .wrapper>a {
        color: #5E5757;
        font-weight: 300;

        &.current {
          color: $nissan-primary-color;

          &:after {
            width: 100%;
            height: rem(2);
            background: $nissan-primary-color;
            left: 0;
            top: rem(36);
            margin-left: 0;
            border-radius: initial;
          }
        }
      }
    }

    @include media-mobile() {
      display: block;
      height: rem(100);
      padding: 0;
      padding-top: rem(10);

      &__label {
        margin-bottom: .4rem;
        display: flex;
        padding-left: .8rem;
        align-self: flex-start;
        font-size: .65rem;
        font-weight: 400;
        line-height: .9rem;
        color: #000;
      }

      .slick-slide {
        &:first-child {
          padding-left: rem(16);
        }
      }

      .slick-list {
        &:after {
          content: ' ';
          display: block;
          position: absolute;
          width: rem(50);
          height: 100%;
          right: 0;
          bottom: 0;
          box-shadow: inset rem(-50) 0 rem(40) rem(-20) rgba(#eee, .9);
        }
      }

      .wrapper {
        height: auto;

        &>a {
          display: inline-block;
          height: rem(48);
          width: rem(80);
          background: $nissan-secondary-color;
          color: rgba(0, 0, 0, .4);
          font-size: rem(16);
          font-weight: 400;
          border-radius: rem(4);
          padding: rem(13);
          margin: 0 rem(8) rem(10);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
          opacity: 1;
          text-align: center;

          &.current {
            font-weight: 700;
            background: $nissan-primary-color;
            color: $nissan-secondary-color;
            transform: none;

            &:after {
              content: none
            }
          }
        }

      }
    }
  }

  .VehicleGroupSelector,
  .VehicleModelFamily {
    position: relative;
    padding-top: rem(10);

    &:before,
    &:after {
      box-shadow: none;
    }

    .group {
      width: 100%;
      height: 0;
      box-sizing: content-box;
      margin: rem(7) rem(5);
      display: flex;
      flex: 0 0 auto;
      align-items: flex-start;

      background: #fff;
      color: #2b2b2b;
      border-radius: rem(4);

      transition: all 0.3s ease;
      min-height: rem(56);

      overflow: hidden;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);

      &>a {
        display: flex;
        align-items: center;
        text-decoration: none;
        position: relative;
        width: 100%;
        height: 100%;
        font-size: rem(18);
        font-weight: 700;
        color: #2b2b2b;
        padding: rem(16) rem(21);
        cursor: pointer;
        text-align: left;
        overflow: hidden;
      }
    }

    .group-inner {
      padding: 0 rem(20) rem(40) rem(16);
    }
  }

  .VehicleGroupSelector {
    @include media-mobile() {
      &:before {
        content: none;
      }

      .group {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
      }

      .group-inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 rem(20) rem(40) rem(16);
        min-height: 100%;
        margin-bottom: rem(1);
        background: $nissan-body-color;
      }
    }
  }

  .VehicleModelSelector,
  .VehicleModelFamily {
    .model {
      display: flex;
      flex-direction: column;
      margin-bottom: rem(32);
      transition: all .3s;
      position: relative;
      padding: 0;

      &:last-child {
        margin-bottom: 0;
      }

      .name {
        font-size: rem(16);
        padding: 0;
        text-decoration: none;
        color: inherit;

        &.name-long {
          font-size: rem(18);
        }

        &.name-line {
          font-size: rem(20);
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          text-overflow: ellipsis;
        }
      }

    }

    .model .icon.icon-arrow-left .stroke {
      stroke: $nissan-primary-color
    }

    .back .icon.icon-arrow-left .stroke {
      stroke: #999;
    }
  }

  .VehicleModelSelector {
    .model-container {
      .model-group {
        display: flex;
        align-items: center;
        height: rem(56);
        font-size: rem(18);
        font-weight: 700;
        padding: rem(16) rem(21);
        border-bottom: 1px solid opacify(#d9dee3, 0.5);
      }

      .model-inner {
        flex: 0 0 auto;
        padding: rem(24) rem(9) rem(24) rem(21);
      }

      .close {
        position: absolute;
        top: rem(15);
        right: rem(15);
        border: none;
        width: rem(25);
        height: rem(25);
        cursor: pointer;
        background: transparent;

        &:before,
        &:after {
          content: ' ';
          display: block;
          position: absolute;
          width: rem(17);
          height: rem(2);
          transform: rotate(45deg);
          background: #999999;
          left: rem(4);
          top: 50%;
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .VehicleGroupFilter {
    .group>button {
      color: #767676;
    }

    .group.current>button {
      box-shadow: inset 0 -4px $nissan-primary-color;
      color: $nissan-primary-color;
    }
  }

  //Folder filters desktop/mobile
  .FoldersFilter {
    li.active {
      color: $nissan-primary-color;

      .icon .stroke {
        stroke: $nissan-primary-color;
      }
    }
  }

  .FoldersFilterDropdown {
    .dropdown-trigger {
      background-color: $nissan-primary-color;
      color: $nissan-secondary-color;
      box-shadow: inset 0 -1px rgba($nissan-secondary-color, .3);

      .icon .stroke {
        stroke: $nissan-secondary-color;
      }
    }

    .dropdown-container {
      background-color: $nissan-primary-color;

      li {
        color: $nissan-secondary-color;
      }
    }
  }

  // Buttons
  .PrimaryButton {
    color: $nissan-contrast-color;
    background: $nissan-secondary-color;
  }

  .SecondaryButton {
    color: $nissan-secondary-color;
    background: $nissan-primary-color;
    border: 1px solid rgba($nissan-secondary-color, .3);
    font-weight: 400;
  }

  .ErrorMessage.inverse,
  .TermsOfServiceModal,
  .screen-overlay {
    .PrimaryButton {
      color: $nissan-secondary-color;
      background: $nissan-primary-color;
    }
  }

  .ErrorMessage:not(.inverse) {
    background-color: $nissan-primary-color;
    color: $nissan-secondary-color;

    .icon .fill {
      fill: rgba($nissan-secondary-color, .5);
    }

    h1 {
      color: $nissan-secondary-color;
    }

    span {
      color: rgba($nissan-secondary-color, .5);
    }
  }

  .Promo {
    .screenshot {
      &.ios {
        background-image: url("../../images/screenshot-nissan-ios.png");
      }

      &.android {
        background-image: url("../../images/screenshot-nissan-android.png");
      }

      &:after {
        box-shadow: inset 0 rem(-180) rem(60) rem(-60) rgba($nissan-primary-color, 1);
      }
    }

    .browse {
      color: $nissan-secondary-color;
    }

    &-sunset {
      .screenshot {

        &.ios,
        &.android {
          background-image: url("../../images/sunset-nissan.png");
        }
      }
    }
  }

  .Topics {
    @include media-mobile {
      .topics-subtitle {
        font-weight: 400;
        background: transparent;
        box-shadow: none;
        padding: rem(14) rem(40) rem(14) rem(20);
      }

      .topic-content {
        padding: rem(0) rem(20) rem(20) rem(20);
        margin: 0;
      }
    }
  }

  .TopicsList {
    @include media-mobile() {
      display: flex;
      flex-flow: column;
      flex: 1 0 auto;
      padding: rem(16) rem(18) rem(17);
      background: #F4F5F7;

      &.TopPicksList {
        padding-top: 0;
      }

      li {
        display: flex;
        box-shadow: 0px rem(4) rem(8) rgba(0, 0, 0, 0.04);
        border-radius: rem(4);
        background: #fff;
        margin-bottom: rem(10);

        &:first-child {
          box-shadow: none;
        }

        .item-inner {
          display: flex;
          flex-flow: row nowrap;
          text-decoration: none;
          position: relative;
          flex: 1;
          padding: rem(12) rem(16) rem(12) rem(16);
          align-items: flex-start;

          .item-title {
            flex: 1;
            padding-right: rem(20);
            transition: all .2s;
            font-weight: 400;
            font-size: rem(16);
            line-height: rem(24);
            color: #000;
            letter-spacing: -0.31px;

            &.return {
              font-weight: 300;
            }
          }
        }
      }
    }

    &.publications {
      @include media-mobile() {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-items: flex-start;
        align-content: start;

        li {
          height: rem(135);
          max-width: 45%;
          flex: 1 0 45%;
          margin-bottom: rem(15);

          &:nth-child(odd) {
            margin-right: rem(15);
          }

          .item-inner {
            flex-flow: column-reverse;
            justify-content: space-evenly;
            align-items: center;

            .item-title {
              flex: 0 1 auto;
              padding: 0;
              text-align: center;
              line-height: rem(22);
              opacity: 0.8;

              // handle long names
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }

          .item-iconbar .icon {
            width: rem(40);
            height: auto;
            margin: 0;

            &.icon-folder {
              width: rem(35);
            }
          }
        }
      }

      li {
        .item-iconbar .icon {
          .fill {
            fill: $nissan-primary-color;
          }
        }
      }
    }
  }

  .SearchInput {
    @include media-mobile {
      padding: rem(12) 0 rem(12) rem(15);
      margin: rem(20) rem(18) rem(0);
      background-color: #fff;
      border-radius: rem(4);
      border: 1px solid #E2E2E2;
      box-shadow: none;
      height: rem(44);

      &+.topics-subtitle {
        background-color: transparent !important;
        box-shadow: none;
        padding: rem(18) rem(18) rem(10) rem(20);
        font-weight: 400;
        line-height: rem(24);

        hr {
          width: 100%;
          height: 1px;
          border: 0;
          background: rgba(0, 0, 0, 0.1);
          margin: 0;
          margin-top: rem(17);
        }
      }

      .SearchInput__icon-post {
        display: none;
      }

      .SearchInput__icon-pre {
        display: block;
      }

      input {
        font-size: rem(16);
        margin: 0;
        padding: 0;
      }

      button.cancel-search {
        align-self: baseline;
        height: 100%;
        padding: 0 rem(15);

        .icon.icon-cancel {
          width: rem(18);
          height: rem(22);
        }
      }
    }
  }
}
