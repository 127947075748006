@import 'common';

.TermsOfServiceModalOverlay {
  @extend %ModalOverlay;
}
.TermsOfServiceModal {
  @extend %Modal;
  @extend %Screen;

  width:auto;
  min-height: 0;
  padding: 0 rem(20) rem(20);

  @include media-desktop {
    padding: 0 rem(65) rem(20) rem(80);
    width:rem(900);
    height:rem(570);
    left: 50%;
    top: 50%;
    margin: rem(-285) 0 0 rem(-450);
    right:auto;
    bottom: auto;
  }

  .terms-head {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0 rem(-20) rem(15);
    padding: rem(10) rem(20) rem(10);
    height: rem(78);

    border-bottom: 1px solid #CCCCCC;

    @include media-desktop {
      margin: 0 rem(-65) rem(32) rem(-80);
      padding: rem(10) rem(65) rem(10) rem(80);
      height: rem(104);
    }
  }

  .terms-logo {
    height: rem(70);
    background-size: contain;
    background-repeat: no-repeat;
    order: 1;

    @include media-desktop {
      height:rem(94);
    }
  }

  .terms-title {
    font-weight:700;
    font-size: rem(14);
    width: 100%;
    text-align: left;
    margin: 0 0 0 rem(14);
    line-height: rem(22);
    order: 3;

    p {
      font-size: rem(18);
      font-weight:700;
    }

    @include media-desktop {
      order: 2;
      width: auto;
      flex: 1;
      font-size: rem(22);
      margin: 0 0 0 rem(32);
      line-height:rem(28);

      p {
        font-size: rem(28);
      }
    }
  }

  .terms-content {
    @extend .screen-content;
    font-weight:100;
    overflow: hidden;

    @include media-desktop() {
      margin-bottom: rem(20);
    }

    .overlay {
      display: block;
      position: absolute;
      width: 100%;
      height: 100px;
      left: 0;
      bottom: 0;
      box-shadow: inset 0 rem(-120) rem(120) rem(-70) rgba(#fff, .9);
      z-index:2;
      pointer-events: none;
    }
  }

  .terms-container {
    @extend .screen-container;

    display: block;

    @include media-desktop {
      position: absolute;
      overflow-y: scroll;
    }

     z-index:1;
  }

  .screen-text {
    p {
      font-size: rem(14);
      line-height: rem(30);

      @include media-desktop {
        font-size:rem(20);
        line-height:rem(34);
      }
    }
  }

  .terms-action {
    order: 3;

    padding: rem(20) rem(10) rem(10);

    display: flex;
    justify-content: space-around;
    align-items: center;

    @include media-desktop {
      justify-content: flex-end;
    }

    .accept {
      width: rem(180);
    }
  }
}
