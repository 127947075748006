@import "../utils";
@import "infiniti_variables";

$infiniti-logo: '../../images/infiniti_logo.svg';
// Infiniti brand theme
$infiniti-primary-color: #000;
$infiniti-secondary-color: #fff;
$infiniti-regular-color: #7c8d98;
$infiniti-contrast-color: #000;
$infiniti-background-color: #f2f2f2;
$infiniti-grey-color: #b4b4b4;

/* BRAND FONTS */

@font-face {
  font-family: 'infiniti_brand';
  src: url('../fonts/infinitibrand-bold-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/infinitibrand-bold-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/infinitibrand-bold-webfont.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/infinitibrand-bold-webfont.woff') format('woff'), // Modern Browsers
    url('../fonts/infinitibrand-bold-webfont.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/infinitibrand-bold-webfont.svg#infiniti_brandbold') format('svg'); // Legacy iOS
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'infiniti_brand';
  src: url('../fonts/infinitibrand-bold-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/infinitibrand-bold-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/infinitibrand-bold-webfont.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/infinitibrand-bold-webfont.woff') format('woff'), // Modern Browsers
    url('../fonts/infinitibrand-bold-webfont.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/infinitibrand-bold-webfont.svg#infiniti_brandbold') format('svg'); // Legacy iOS
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'infiniti_brand';
  src: url('../fonts/infinitibrand-regular-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/infinitibrand-regular-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/infinitibrand-regular-webfont.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/infinitibrand-regular-webfont.woff') format('woff'), // Modern Browsers
    url('../fonts/infinitibrand-regular-webfont.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/infinitibrand-regular-webfont.svg#infiniti_brandregular') format('svg'); // Legacy iOS
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'infiniti_brand';
  src: url('../fonts/infinitibrand-light-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/infinitibrand-light-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/infinitibrand-light-webfont.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/infinitibrand-light-webfont.woff') format('woff'), // Modern Browsers
    url('../fonts/infinitibrand-light-webfont.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/infinitibrand-light-webfont.svg#infiniti_brandlight') format('svg'); // Legacy iOS
  font-weight: 300;
  font-style: normal;
}

// =========================
// Infiniti specific styling
// =========================

.expand-image-modal.Infiniti {
  font-family: 'infiniti_brand', sans-serif;
}

div.infiniti {
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    font-weight: 300;
  }

  & *,
  .topic-content .note * {
    font-family: 'infiniti_brand', sans-serif;
  }

  .topic-content {

    p,
    .p,
    li,
    strong {
      font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    }

    p.grid a {
      border-color: $infiniti-light-gray;
    }
  }

  // Logo
  .logo,
  .screen-logo,
  .terms-logo {
    background-image: url($infiniti-logo);
    background-position: center;
  }

  .screen-logo {
    width: rem(100);
    height: rem(39);
  }

  .terms-logo {
    width: rem(141);
    height: rem(60);
  }

  @include media-desktop {
    .screen-logo {
      margin: 0 rem(20) 0 0;
    }
  }

  // Loader styling
  .Loader {
    background-color: $infiniti-primary-color;

    .status {
      color: $infiniti-primary-color;
    }
  }

  // Splash screen colors
  .TermsOfService,
  .Vehicles,
  .BrandsSelectorScreen,
  .Promo {
    background: $infiniti-background-color;
    color: $infiniti-primary-color;

    .hint,
    .terms {
      color: $infiniti-primary-color;
    }

    @include media-desktop() {
      .screen-title {
        font-weight: normal;
      }
    }
  }

  // Regular screen colors
  .Guide {
    color: $infiniti-regular-color;

    @include media-desktop() {
      .screen-title {
        color: $infiniti-primary-color;
        font-size: rem(26);
        text-transform: uppercase;
        font-weight: 300;
      }
    }
  }

  .Loader {
    background-color: $infiniti-background-color;

    .loader-default {
      &>div {
        background-color: $infiniti-primary-color;
      }
    }

    .loader-ios {
      @keyframes spinner-ios-infiniti {
        0% {
          background-color: $infiniti-primary-color;
        }

        100% {
          background-color: transparent;
        }
      }

      &>div {
        animation-name: spinner-ios-infiniti;
      }
    }

    .loader-android {
      circle {
        stroke: $infiniti-primary-color;
      }
    }

    &.inverse {
      background-color: $infiniti-secondary-color;
      color: $infiniti-regular-color;
    }
  }

  .NavBar {
    background: $infiniti-primary-color;

    .nav-link {
      color: #B4B4B4;
      opacity: 1;
      font-weight: 400;

      &.active {
        font-weight: 700;
        color: $infiniti-secondary-color;
      }

    }

    @include media-mobile() {
      background-color: $infiniti-secondary-color;

      .nav-link {
        color: $infiniti-regular-color;

        .icon {
          .active {
            display: none;
          }

          .fill {
            fill: $infiniti-regular-color;
          }

          .stroke {
            stroke: $infiniti-regular-color;
          }
        }

        &.active {
          color: $infiniti-primary-color;

          .icon .active {
            display: inherit;
          }

          .icon .inactive {
            display: none;
          }

          .icon .fill {
            fill: $infiniti-primary-color;
          }

          .icon .stroke {
            stroke: $infiniti-primary-color;
          }
        }
      }
    }
  }

  .nav-link {
    color: $infiniti-grey-color;
    opacity: 1;

    &.active {
      color: $infiniti-secondary-color;
    }
  }

  .VehicleList {
    background: $infiniti-secondary-color;

    h2 {
      color: $infiniti-contrast-color;
      font-weight: 700;
      border-bottom-color: $infiniti-gray;
    }

    .model {
      color: $infiniti-regular-color;

      &:hover {
        .name {
          font-weight: 400;
        }
      }

      .name {
        color: $infiniti-contrast-color;
        font-size: rem(19);
        font-weight: 300;
      }
    }
  }

  .VehicleModelSelector {
    .model-group {
      text-transform: uppercase;
    }
  }

  .VehicleYearSelector {
    background: $infiniti-primary-color;

    .wrapper {
      & > a {
        color: $infiniti-grey-color;
        opacity: 1;
        margin-bottom: 0;

        &.current {
          color: $infiniti-secondary-color;
          opacity: 1;
        }
      }
    }
  }

  .VehicleGroupSlider,
  .VehicleGroupSelector {
    .group {
      box-shadow: 0 rem(10) rem(20) 0 rgba(0, 0, 0, 0.1);
    }
  }

  .VehicleGroupSelector {
    .group>button {
      text-transform: uppercase;
    }
  }

  //Folder filters desktop/mobile
  .FoldersFilter li.active {
    color: $infiniti-primary-color;

    .icon .stroke {
      stroke: $infiniti-primary-color;
    }
  }

  .FoldersFilterDropdown {
    .dropdown-trigger {
      color: $infiniti-primary-color;
      font-weight: 800;

      .icon .stroke {
        stroke: $infiniti-primary-color;
      }
    }

    &.opened .dropdown-trigger {
      box-shadow: inset 0 -1px #e5e5e5;
    }
  }

  // Buttons
  .PrimaryButton,
  .SecondaryButton {
    color: $infiniti-primary-color;
    background: transparent;
    font-weight: 300;
    text-transform: uppercase;
    border: 1px solid $infiniti-primary-color;
    border-radius: 0;
    font-size: rem(16);
    padding: rem(15) rem(20);
  }

  .ErrorMessage:not(.inverse) {
    background-color: $infiniti-background-color;
    color: $infiniti-regular-color;

    .icon .fill {
      fill: rgba($infiniti-regular-color, .3);
    }
  }

  .Promo {
    .logo {
      width: rem(120);
      height: rem(120);
    }

    .head {
      h1 {
        font-weight: 300;
        text-transform: uppercase;
      }
    }

    .screenshot {
      &.ios {
        background-image: url("../../images/screenshot-infiniti-ios.png");
      }

      &.android {
        background-image: url("../../images/screenshot-infiniti-android.png");
      }

      &:after {
        box-shadow: inset 0 rem(-180) rem(60) rem(-60) rgba($infiniti-background-color, 1);
      }
    }

    .browse {
      color: #666;
    }
  }

  // Terms and conditions styling
  .TermsOfServiceModal {
    .terms-head {
      border-bottom-color: #E5E5E5;
    }

    .terms-title {
      font-weight: 700;
      text-transform: uppercase;
      font-size: rem(14);
      margin-left: rem(50);

      p {
        font-weight: 300;
        font-size: rem(28);
      }

      @include media-mobile {
        font-size: rem(14);
        line-height: rem(22);

        p {
          font-size: rem(18);
        }
      }
    }

    .screen-text p {
      font-weight: 300;
      font-size: rem(17);
    }

    .terms-action button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $infiniti-primary-color;
      border-radius: 100px;
      color: $infiniti-secondary-color;
      text-transform: none;
      width: rem(115);
      height: rem(36);
    }
  }

  .Topics {
    @include media-desktop {
      .topic-title {
        font-size: rem(34);
        text-transform: none;
        font-weight: 300;
      }
    }

    .topics-breadcrumb {
      color: $infiniti-contrast-color;

      li:last-child a {
        font-weight: 700;
      }
    }

    .topics-breadcrumb,
    .topics-subtitle {
      box-shadow: none;
      margin-bottom: 0;
    }
  }

  .PdfViewer {
    .topics-breadcrumb li:last-child {
      font-weight: 700;
    }

    .topics-breadcrumb {
      li {
        a {
          color: $infiniti-primary-color;
        }

        &:last-child {
          font-weight: bold;
          color: $infiniti-primary-color;
        }
      }
    }
  }

  .Explore {
    .visual-search .image-map-container {
      .hot-spot {
        background-color: $infiniti-signal-error;
      }

      .image-map-selector {
        .button-name {
          color: $infiniti-primary-color;
        }

        button {
          border-color: $infiniti-gray;

          @include media-desktop() {
            height: rem(130);
            padding-bottom: rem(28);
          }

          &.active {
            background-color: $infiniti-primary-color;
            border-color: $infiniti-primary-color;

            .icon {
              .fill {
                fill: $infiniti-secondary-color;
              }
              .stroke {
                stroke: $infiniti-secondary-color;
              }
            }
            .button-name {
              color: $infiniti-secondary-color;
            }
          }

          .icon {
            @include media-desktop() {
              width: rem(55);

              &.icon-seatInfiniti {
                width: rem(40)
              }
            }

            .fill {
              fill: $infiniti-primary-color;
            }
            .stroke {
              stroke: $infiniti-primary-color;
            }
          }
        }
      }
    }

    .topics h2 {
      font-size: rem(14);
      line-height: 1.6em;
      text-transform: uppercase;
      margin: rem(-10) 0 0;
      padding-bottom: rem(16);
      border: none;
    }
  }

  .TopicsList {
    &.publications {
      @include media-mobile() {
        li .item-iconbar .icon {
          width: rem(22);

          .fill {
            fill: $infiniti-primary-color;
          }
        }
      }
    }
  }

  .SearchInput {
    input {
      font-weight: 300;
    }

    &+.topics-subtitle {
      hr {
        display: none;
      }
    }

    .icon.icon-cancel {
      .fill {
        fill: #495360;
        opacity: .5;
      }
    }

    input::placeholder {
      color: $infiniti-primary-color;
      opacity: 1;
    }
  }

  .PdfMatches {

    &__search-result {

      &:hover {
        > div {
          color: $infiniti-contrast-color;
          font-weight: 400;
        }
      }

    }

    &__search-result-match {
      em {
        font-weight: 700;
      }
    }
  }

  .SortSelector {
    &__btn {
      font-weight: 300;
    }
  }

  .screen-head {
    .topmenu-link {
      font-size: rem(11);
      font-weight: 400;
    }
  }
}
