@import 'common';

.TopicsList {
  $list-color: #495360;
  $icon-color: #dfdfdf;

  font-size: rem(18);
  font-weight: 100;
  line-height: rem(28);
  @include media-mobile {
    padding-left:rem(20);
  }

  &__match-aside {
    display: inline-block;
  }

  &__match-subtitle {
    font-size: 14px;

    @include media-desktop {
      color: #495360;
      opacity: 0.5;
    }
  }

  &.publications {
    @include media-desktop() {
      display: flex;
      flex: 0 0 auto !important;
      flex-flow: row wrap;

      li {
        flex: 50%;

        .item-inner {
          margin-bottom: rem(10);

          .item-iconbar .icon {
            width: rem(30);
            height: auto;
            margin: 0;

            .fill {
              fill: #000;
            }
          }
        }

      }
    }
  }

  .Loader--match {
    display: inline-flex;
    width: auto;

    .loader-default > div {
      width: 0.4rem;
      height: 0.4rem;
    }
  }

  li {
    box-shadow: inset 0 1px #cfdbe6;
    @include media-desktop() {
      box-shadow: none;
    }

    display: flex;

    &:first-child {
      box-shadow: none;
    }

    .item-inner {
      display: flex;
      flex-flow: row nowrap;
      text-decoration: none;
      font-weight: 300;
      position: relative;
      flex: 1;
      padding: rem(16) rem(20) rem(16) 0;
      align-items: flex-start;

      .item-title {
        flex: 1;
        padding-right:rem(20);
        color: $list-color;
        transition: all .2s;

        &.return {
          //color: lighten($list-color, 20%);
          font-weight:300;
        }

        &:hover {
          .CopyLinkBtn {
            visibility: visible;
            opacity: 1;
            pointer-events: all;
          }
        }

        .highlighted {
          mark {
            background-color: #f5f5f5;
          }
        }
      }

      @include media-desktop {
        //transition: all .2s;
        //transform-origin: 0 0;
        padding: rem(10) 0 rem(10) 0;
        flex-direction: row-reverse;
        align-items: flex-start;

        .item-title {
          padding-left:rem(22);
          padding-right:0;
          font-weight:300;
          font-size:rem(20);
        }

        .item-iconbar {
          align-self: center;

          .icon {
            width:rem(20);
            height:rem(20);
            margin: rem(4) 0 0 rem(2);

            &.icon-folder {
              width:rem(23);
              height:rem(18);
            }
            &.icon-list-topic {
              width:rem(18);
              height:rem(22);
            }
          }
        }

        &:hover {
          //transform: scale(1.02);

          .item-title {
            color: #000;
          }
          .icon .fill {
            fill: darken($icon-color, 10%);
          }
        }
      }
    }

    .item-media {
      padding:0 rem(10) rem(16) 0;

      @include media-desktop {
        padding-top: rem(2);
      }
      cursor: pointer;

      .icon {
        display: block;
        width: rem(24);
        height: rem(24);
        @include media-mobile {
          margin: rem(18) 0 0;
        }

        &.icon-bookmark .stroke {
          stroke: #BAC5D1;
        }

        &.icon-bookmark.active {
          .fill {
            fill: #7F8D97;
          }
          .stroke {
            stroke: #7F8D97;
          }
        }

        &.icon-arrow-return .fill {
          fill: lighten(#BAC5D1, 5%);
        }
      }
    }

    .item-iconbar {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      .icon {
        display: block;
        width: rem(16);
        height: rem(16);
        margin: rem(6) 0 0 rem(5);

        .fill {
          transition: fill .2s;
          fill: $icon-color;
        }


        &.icon-arrow-left {
          width: rem(8);
          height:rem(14);
          transform: rotate(180deg);

          .stroke {
            stroke: $list-color;
          }

        }
      }
    }
  }
}
