@import "../../styles/brands/infiniti_variables.scss";
@import "../../styles/utils";

li.InfinitiTopicsList__item {
  box-shadow: inset 0 -1px $infiniti-gray;

  &:first-child {
    box-shadow: inset 0 -1px $infiniti-gray;
  }

  &:hover {
    .item-inner .item-title .TopicsList__match-title {
      span {
        font-weight: 400;
      }

      font-weight: 400;
    }
  }

  .item-inner {
    .item-title {
      color: $infiniti-contrast-color;
      padding-left: 0;

      .TopicsList__match {
        &-subtitle-wrapper {
          display: flex;
          color: $infiniti-dark-gray;
          font-size: rem(14);

          .Loader {
            max-width: fit-content;
          }

          span.TopicsList__match-subtitle {
            color: inherit;
            opacity: 1;
            font-weight: 300;

            &-type {
              display: flex;
              align-items: center;
              gap: rem(9);
              color: $infiniti-dark-gray;
              font-weight: 300;
            }
          }
        }

        &-title {
          font-size: rem(19);
          font-weight: 300;

          @include media-desktop() {
            transition: none;
          }

          .icon-arrow-left {
            position: absolute;
            width: 0.4rem;
            height: 0.7rem;
            right: 0.75rem;
            top: 50%;
            margin-top: -0.35rem;
            transform: rotate(180deg);

            .stroke {
              stroke: $infiniti-contrast-color;
              stroke-width: 1;
            }
          }
        }
      }

      .icon {
        display: inline-block;
        width: rem(18);
      }
    }

  }
}
