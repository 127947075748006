@import "common";

.NavBar {
  width: 100%;
  height: rem(50);
  flex: 0 0 rem(50);

  display: flex;
  flex-flow:row nowrap;
  align-items: center;
  background: #F5F7F9;

  @include media-desktop(){
    position: absolute;
    height:rem(55);
    top: 85px;
    left: 0;
    padding-left: rem(115);
    background: #000;
  }
  @include media-mobile(){
    box-shadow: 0 -1px 0 0 rgba(207, 218, 230, 0.5), 0 0 10px 0 rgba(0, 0, 0, 0.14);
    background-color: #fff;
    position: relative;
    z-index: 3;

    &:empty {
      display: none;
    }
  }

  .nav-head {
    flex: 0 0 auto;
  }

  .nav-logo {
    display: block;
    width:rem(80);
    height:rem(80);
    background-size: rem(55) auto;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .nav-link {
    @include media-mobile(){
      @include TouchableFeedback(rgba(20,20,20,0.5));
    }

    color: #000000;
    height: 100%;
    font-size:rem(11);
    display: flex;
    flex:1;
    flex-flow: column nowrap;
    align-items:center;
    justify-content:center;
    text-align: center;

    text-decoration: none;

    .icon {
      display: block;
      width: rem(24);
      height: rem(24);
      margin: rem(0) rem(3) rem(5);

      .active {
        display: none;
      }
      .fill {
        // see brand specific theme coloring
      }
      .stroke {
        // see brand specific theme coloring
      }
    }

    @include media-desktop(){
      flex:0 0 auto;
      height: 100%;
      display: flex;
      justify-content: center;

      font-size:rem(14);
      margin:0 rem(15);
      padding:0 rem(15);
      text-transform: uppercase;
      padding-bottom: rem(2);

      color: #fff;
      opacity:.4;

      .icon {
        display: none;
      }

      &:first-child {
        margin-left:rem(-15);
      }
    }

    &.active {
      @include media-desktop(){
        opacity:1;
      }

      // see brand specific theme coloring

      .icon .active {
        display: inherit;
      }
      .icon .inactive {
        display: none;
      }
      .icon .fill {
        // see brand specific theme coloring
      }
      .icon .stroke {
        // see brand specific theme coloring
      }
    }
  }

  .nav-foot {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

}
