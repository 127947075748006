@import "common";

.Promo {
  @extend %Screen;

  align-items: center;

  .container {
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    @include media-mobile("landscape") {
      .text {
        width: auto;
        padding: rem(20) rem(50)
      }
      .screenshot {
        width: auto;
        flex: 1 0 auto;
        background: none !important;

        .download {
          position: static;
          margin:0;
        }

        &:after {
          display: none;
        }
      }

    }
  }

  .head {
    flex:1 0 auto;
    display: flex;
    flex-flow: column nowrap;
    align-items:center;
    justify-content:center;

    .logo {
      width:rem(77);
      height:rem(67);
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }

    h1 {
      font-size: rem(26);
      font-weight: bold;
      margin: 0;
    }
  }

  .text {
    flex:1 0 auto;
    display: flex;
    align-items:center;
    width:rem(285);
    font-size: rem(14);
    line-height:rem(24);
    text-align: center;
  }

  .screenshot {
    flex: 1 0 rem(220);
    width: rem(140);
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      width: 100%;
      height: rem(200);
      left: 0;
      bottom: 0;
      box-shadow: inset 0 rem(-180) rem(60) rem(-60) rgba(#fff, 1);
      z-index:2;
    }

    .download {
      display: block;
      position: absolute;
      bottom: rem(20);
      left: 50%;
      margin-left:rem(-81);
      width:rem(168);
      height:rem(50);
      //padding: rem(13) rem(36);
      background-size: cover;
      z-index: 3;
    }

    &.ios {
      .download {
        background-image: url("../images/appstore-badge.png");
      }
    }

    &.android {
      .download {
        background-image: url("../images/play-badge.png");
      }
    }
  }

  .browse {
    flex: 1;
    display: flex;
    align-items:center;
    text-align: center;
    font-size: rem(14);
    line-height: 150%;
    font-weight:300;
    color: #000;
    text-decoration: none;
    margin:rem(10) 0 rem(10) 0;
  }

  &-sunset {
    div.container {
      justify-content: space-evenly;

      div.screenshot {
        display: flex;
        justify-content: center;
        width: rem(180);
        background-position: top;

        &::after {
          content: none;
        }

        .download {
          bottom: rem(0);
          width: rem(205);
          height: rem(60);
          margin: 0;
          left: auto;
        }
      }
    }

    .logo {
      margin-bottom: rem(10);
    }

    .head,
    .text,
    .screenshot {
      flex-grow: 0;
    }

    .close {
      position: absolute;
      width: rem(20);
      top: rem(30);
      right: rem(25);

      .stroke {
        stroke: #fff;
      }
    }
  }
}
