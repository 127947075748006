@import "../utils";

// Nissan brand theme
$generic-primary-color: #454f56;
$generic-secondary-color: #fff;
$generic-regular-color: #7c8d98;
$nissan-contrast-color: #000;
$nissan-background-color: #f2f2f2;
$generic-logo: '../../images/generic_logo.png';

// =========================
// No-brand specific styling
// TODO: move other default styling into components
// =========================

div.generic {

  .logo {
    background-size: contain;
  }

  .screen-logo,
  .terms-logo {
    background-image: url($generic-logo);
  }

  .screen-logo {
    width: rem(75);
    height: rem(75);
  }

  .terms-logo {
    width: rem(55);
    height: rem(55);
  }

  @include media-desktop {
    .terms-logo {
      width: rem(76);
      height: rem(76);
    }

    .terms-title {
      margin-top: rem(38);
    }

    .terms-version {
      margin-top: rem(20);
    }

    .screen-topmenu .topmenu-link {
      font-weight: bold;
    }
  }

  // Splash screen colors
  .Vehicles,
  .BrandsSelectorScreen,
  .Promo {
    background: $generic-primary-color;
    color: $generic-secondary-color;

    .screen-title {
      color: $nissan-contrast-color;
    }

    @include media-mobile() {
      .screen-head {
        background-color: transparent;
        box-shadow: none;
      }

      .screen-title,
      .screen-back>* {
        color: $generic-secondary-color;
      }

      .screen-back .icon .stroke {
        stroke: $generic-secondary-color;
      }

      .screen-topmenu {
        .topmenu-trigger .icon .fill {
          fill: $generic-secondary-color;
        }

        .topmenu-inner {
          box-shadow: 0 rem(10) rem(15) rem(-5) rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  // Regular screen colors
  .Guide {
    color: $generic-regular-color;
    background: $generic-secondary-color;
  }

  //loader styling
  .Loader {
    background-color: $generic-primary-color;

    .loader-default {
      &>div {
        background-color: $generic-secondary-color;
      }
    }

    .loader-ios {
      @keyframes spinner-ios-nissan {
        0% {
          background-color: $generic-secondary-color;
        }

        100% {
          background-color: transparent;
        }
      }

      @keyframes spinner-ios-nissan-inverse {
        0% {
          background-color: $generic-primary-color;
        }

        100% {
          background-color: transparent;
        }
      }

      &>div {
        animation-name: spinner-ios-nissan;
      }
    }

    .loader-android {
      circle {
        stroke: $generic-secondary-color;
      }
    }

    &.inverse {
      background-color: $generic-secondary-color;
      color: $generic-regular-color;

      .loader-default>div {
        background-color: $generic-primary-color;
      }

      .loader-ios>div {
        animation-name: spinner-ios-nissan-inverse;
      }

      .loader-android {
        circle {
          stroke: $generic-primary-color;
        }
      }
    }
  }

  .NavBar {
    @include media-mobile() {
      .nav-link {
        color: $generic-regular-color;

        .icon {
          .active {
            display: none;
          }

          .fill {
            fill: $generic-regular-color;
          }

          .stroke {
            stroke: $generic-regular-color;
          }
        }

        &.active {
          color: $generic-primary-color;

          .icon .active {
            display: inherit;
          }

          .icon .inactive {
            display: none;
          }

          .icon .fill {
            fill: $generic-primary-color;
          }

          .icon .stroke {
            stroke: $generic-primary-color;
          }
        }
      }
    }
  }

  .VehicleList {
    background: $generic-secondary-color;

    h2 {
      color: $nissan-contrast-color;
    }

    .model {
      color: $generic-regular-color;

      .name {
        color: $nissan-contrast-color;
      }
    }
  }

  .VehicleYearSelector {
    @include media-mobile() {
      .slick-list:after {
        content: ' ';
        display: block;
        position: absolute;
        width: rem(50);
        height: 100%;
        right: 0;
        bottom: 0;
        box-shadow: inset rem(-50) 0 rem(40) rem(-20) rgba($generic-primary-color, .9);
      }

      .wrapper button {
        color: $generic-secondary-color;
        font-weight: 300;
      }
    }
  }

  //Folder filters desktop/mobile
  .FoldersFilter {
    li.active {
      color: $generic-primary-color;

      .icon .stroke {
        stroke: $generic-primary-color;
      }
    }
  }

  .FoldersFilterDropdown {
    .dropdown-trigger {
      background-color: $generic-primary-color;
      color: $generic-secondary-color;
      box-shadow: inset 0 -1px rgba($generic-secondary-color, .3);

      .icon .stroke {
        stroke: $generic-secondary-color;
      }
    }

    .dropdown-container {
      background-color: $generic-primary-color;

      li {
        color: $generic-secondary-color;
      }
    }
  }

  // Buttons
  .PrimaryButton {
    color: $nissan-contrast-color;
    background: $generic-secondary-color;
  }

  .SecondaryButton {
    color: $generic-secondary-color;
    background: $generic-primary-color;
    border: 1px solid rgba($generic-secondary-color, .3);
    font-weight: 400;
  }

  .VehicleGroupSlider,
  .ErrorMessage.inverse,
  .TermsOfServiceModal {
    .PrimaryButton {
      color: $generic-secondary-color;
      background: $generic-primary-color;
    }
  }

  .ErrorMessage:not(.inverse) {
    background-color: $generic-primary-color;
    color: $generic-secondary-color;

    .icon .fill {
      fill: rgba($generic-secondary-color, .5);
    }

    h1 {
      color: $generic-secondary-color;
    }

    span {
      color: rgba($generic-secondary-color, .5);
    }
  }

  .Promo {
    .screenshot {
      &.ios {
        background-image: url("../../images/screenshot-nissan-ios.png");
      }

      &.android {
        background-image: url("../../images/screenshot-nissan-android.png");
      }

      &:after {
        box-shadow: inset 0 rem(-180) rem(60) rem(-60) rgba($generic-primary-color, 1);
      }
    }

    .browse {
      color: $generic-secondary-color;
    }
  }

  // Terms and conditions styling
  .TermsOfService .terms-content {

    // Shadow above "Accept" and "Decline" buttons in mobile version
    .overlay {
      box-shadow: inset 0 rem(-120) rem(120) rem(-70) rgba($generic-primary-color, .9);
    }

    @include media-desktop {
      background-color: $generic-secondary-color;
      color: $generic-regular-color;

      .overlay {
        box-shadow: inset 0 rem(-120) rem(120) rem(-70) rgba($generic-secondary-color, .9);
      }
    }
  }

  @include media-desktop {
    .TermsOfService .terms-action {
      background-color: $generic-secondary-color;
    }

    .TermsOfService .terms-action .accept {
      color: $generic-secondary-color;
      background: $generic-primary-color;
      border: 1px solid $generic-primary-color;

      font-weight: 400;
    }

    .TermsOfService .terms-action .decline {
      color: $nissan-contrast-color;
      background: $generic-secondary-color;
      border: 1px solid rgba($nissan-contrast-color, .3);

      font-weight: 400;
    }
  }

  .Topics {
    @include media-mobile {
      .topic-title {
        font-weight: bold;
      }
    }
  }
}
